import React from 'react';
import { CMSParseImage } from '@components/StrapiComponents/ImageParser';
import { transformToSlug } from 'helpers/slugHelper';
import { PATHS } from '@global/constants/urls';
import {
  ImageContainer,
  DataContainer,
  Tag,
  TitleContainer,
  SubtitleContainer,
  TrainingDate,
  SeparateLine,
  TrainingSchedule,
  TrainingContainer,
  TrainingTitleContainer,
  TrainingTitleTooltip,
} from './trainingCard.styles';
import { TrainingCardProps } from './trainingCard.types';

const TrainingCard = ({ training, renderTooltip }: TrainingCardProps) => {
  const handleSchedule = (schedule: string) => {
    const scheduleDate = new Date(schedule);
    const scheduleHours = scheduleDate.getHours();
    const scheduleMinutes = scheduleDate.getMinutes();
    const scheduleWeekDate = scheduleDate.toLocaleString('en-us', { weekday: 'long' });

    if (!Number.isNaN(scheduleHours) && !Number.isNaN(scheduleMinutes)) {
      return (
        <>
          {scheduleDate.getDate()}
          <TrainingDate>
            {scheduleDate
              .toLocaleString('default', {
                month: 'short',
              })
              .toUpperCase()}
            <SeparateLine>|</SeparateLine>
            {scheduleWeekDate}
            <SeparateLine>|</SeparateLine>
            {scheduleHours > 10 ? scheduleHours : `0${scheduleHours}`}:
            {scheduleMinutes > 10 ? scheduleMinutes : `0${scheduleMinutes}`}
          </TrainingDate>
        </>
      );
    }
    return <TrainingDate>{schedule}</TrainingDate>;
  };
console.log(training);

  return (
    <TrainingContainer
      key={training.attributes.Title}
      href={`${PATHS.TRAINING}/${transformToSlug(training.attributes.Title.toLowerCase())}`}
    >
      <ImageContainer>
        <Tag>Training</Tag>
        {CMSParseImage(training.attributes.Small_Image.data)}
      </ImageContainer>
      <DataContainer className="training-data">
        <TrainingSchedule>{handleSchedule(training.attributes.Schedule)}</TrainingSchedule>
        <TrainingTitleContainer>
          <TitleContainer className="training-title">{training.attributes.Title}</TitleContainer>
          {renderTooltip?.includes(training.attributes.Title) && (
            <TrainingTitleTooltip className="training-title-tooltip">
              {training.attributes.Title}
            </TrainingTitleTooltip>
          )}
        </TrainingTitleContainer>
        <SubtitleContainer>{training.attributes.Subtitle}</SubtitleContainer>
      </DataContainer>
    </TrainingContainer>
  );
};

export default TrainingCard;
